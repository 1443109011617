<template>
  <div class="match-score">
    {{ vehicle.Rank }}
    <div class="row">
      <div class="col">
        <h3 class="match-text" :class="matchType.class">
          {{ matchType.text }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label>Match score: {{ matchScore }}</label>
        <score-bar :value="matchScore" />
      </div>
      <div class="col-6">
        <label>Value score: {{ valueScore }}</label>
        <score-bar :value="valueScore" />
      </div>
    </div>
  </div>
</template>
<script>
const ScoreBar = () => import("Components/ScoreBar");
export default {
  data() {
    return {
      matchTypes: {
        best: {
          class: "best",
          text: "Best Match",
        },
        great: {
          class: "great",
          text: "Great Match",
        },
        not: {
          class: "not",
          text: "Near Match",
        },
      },
    };
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  methods: {
    getScoreDisplay(score) {
      if (score) {
        return Number((score * 100).toFixed(2));
      }
      return "N/A";
    },
  },
  computed: {
    matchType() {
      if (this.vehicle.Matched === true) {
        return this.vehicle.rank === 1
          ? this.matchTypes.best
          : this.matchTypes.great;
      } else {
        return this.matchTypes.not;
      }
    },
    matchScore() {
      return this.getScoreDisplay(this.vehicle.Total_Score);
    },
    valueScore() {
      return this.getScoreDisplay(this.vehicle.Value_Score);
    },
  },
  components: {
    ScoreBar,
  },
};
</script>
<style lang="scss">
.match-score {
  font-family: $bold-font-family;
  h3.match-text {
    font-size: 1.25rem;
    margin-bottom: 6px;
  }
  label {
    font-size: 0.8125rem;
    color: #162b32;
    font-weight: 700;
    margin-bottom: 2px;
  }
  .best {
    color: #30ca34;
  }
  .great {
    color: #797979;
  }
  .not {
    color: white;
  }
}
</style>
